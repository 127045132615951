/**
 * This code was GENERATED using the solita package.
 * Please DO NOT EDIT THIS FILE, instead rerun solita to update it or write a wrapper to add functionality.
 *
 * See: https://github.com/metaplex-foundation/solita
 */

import * as beet from '@metaplex-foundation/beet'
import * as web3 from '@solana/web3.js'

/**
 * @category Instructions
 * @category CloseRuleset
 * @category generated
 */
export const CloseRulesetStruct = new beet.BeetArgsStruct<{
  instructionDiscriminator: number
}>([['instructionDiscriminator', beet.u8]], 'CloseRulesetInstructionArgs')
/**
 * Accounts required by the _CloseRuleset_ instruction
 *
 * @property [_writable_] ruleset
 * @property [_writable_, **signer**] authority
 * @category Instructions
 * @category CloseRuleset
 * @category generated
 */
export type CloseRulesetInstructionAccounts = {
  ruleset: web3.PublicKey
  authority: web3.PublicKey
}

export const closeRulesetInstructionDiscriminator = 2

/**
 * Creates a _CloseRuleset_ instruction.
 *
 * @param accounts that will be accessed while the instruction is processed
 * @category Instructions
 * @category CloseRuleset
 * @category generated
 */
export function createCloseRulesetInstruction(
  accounts: CloseRulesetInstructionAccounts,
  programId = new web3.PublicKey('ccsxqYAg64wuLEh45KabyPvkKjrEfDPsDZUQrGn7mf3')
) {
  const [data] = CloseRulesetStruct.serialize({
    instructionDiscriminator: closeRulesetInstructionDiscriminator,
  })
  const keys: web3.AccountMeta[] = [
    {
      pubkey: accounts.ruleset,
      isWritable: true,
      isSigner: false,
    },
    {
      pubkey: accounts.authority,
      isWritable: true,
      isSigner: true,
    },
  ]

  const ix = new web3.TransactionInstruction({
    programId,
    keys,
    data,
  })
  return ix
}
