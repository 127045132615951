/**
 * This code was GENERATED using the solita package.
 * Please DO NOT EDIT THIS FILE, instead rerun solita to update it or write a wrapper to add functionality.
 *
 * See: https://github.com/metaplex-foundation/solita
 */

import * as beet from '@metaplex-foundation/beet'
import * as web3 from '@solana/web3.js'
import { InitRulesetIx, initRulesetIxBeet } from '../types/InitRulesetIx'

/**
 * @category Instructions
 * @category InitRuleset
 * @category generated
 */
export type InitRulesetInstructionArgs = {
  initRulesetIx: InitRulesetIx
}
/**
 * @category Instructions
 * @category InitRuleset
 * @category generated
 */
export const InitRulesetStruct = new beet.FixableBeetArgsStruct<
  InitRulesetInstructionArgs & {
    instructionDiscriminator: number
  }
>(
  [
    ['instructionDiscriminator', beet.u8],
    ['initRulesetIx', initRulesetIxBeet],
  ],
  'InitRulesetInstructionArgs'
)
/**
 * Accounts required by the _InitRuleset_ instruction
 *
 * @property [_writable_] ruleset
 * @property [**signer**] authority
 * @property [_writable_, **signer**] payer
 * @category Instructions
 * @category InitRuleset
 * @category generated
 */
export type InitRulesetInstructionAccounts = {
  ruleset: web3.PublicKey
  authority: web3.PublicKey
  payer: web3.PublicKey
  systemProgram?: web3.PublicKey
}

export const initRulesetInstructionDiscriminator = 0

/**
 * Creates a _InitRuleset_ instruction.
 *
 * @param accounts that will be accessed while the instruction is processed
 * @param args to provide as instruction data to the program
 *
 * @category Instructions
 * @category InitRuleset
 * @category generated
 */
export function createInitRulesetInstruction(
  accounts: InitRulesetInstructionAccounts,
  args: InitRulesetInstructionArgs,
  programId = new web3.PublicKey('ccsxqYAg64wuLEh45KabyPvkKjrEfDPsDZUQrGn7mf3')
) {
  const [data] = InitRulesetStruct.serialize({
    instructionDiscriminator: initRulesetInstructionDiscriminator,
    ...args,
  })
  const keys: web3.AccountMeta[] = [
    {
      pubkey: accounts.ruleset,
      isWritable: true,
      isSigner: false,
    },
    {
      pubkey: accounts.authority,
      isWritable: false,
      isSigner: true,
    },
    {
      pubkey: accounts.payer,
      isWritable: true,
      isSigner: true,
    },
    {
      pubkey: accounts.systemProgram ?? web3.SystemProgram.programId,
      isWritable: false,
      isSigner: false,
    },
  ]

  const ix = new web3.TransactionInstruction({
    programId,
    keys,
    data,
  })
  return ix
}
