/**
 * This code was GENERATED using the solita package.
 * Please DO NOT EDIT THIS FILE, instead rerun solita to update it or write a wrapper to add functionality.
 *
 * See: https://github.com/metaplex-foundation/solita
 */

import * as web3 from '@solana/web3.js'
import * as beet from '@metaplex-foundation/beet'
import * as beetSolana from '@metaplex-foundation/beet-solana'
export type InitRulesetIx = {
  name: string
  allowedPrograms: web3.PublicKey[]
  disallowedAddresses: web3.PublicKey[]
  extensions: web3.PublicKey[]
}

/**
 * @category userTypes
 * @category generated
 */
export const initRulesetIxBeet = new beet.FixableBeetArgsStruct<InitRulesetIx>(
  [
    ['name', beet.utf8String],
    ['allowedPrograms', beet.array(beetSolana.publicKey)],
    ['disallowedAddresses', beet.array(beetSolana.publicKey)],
    ['extensions', beet.array(beetSolana.publicKey)],
  ],
  'InitRulesetIx'
)
